.slice-landing-section-title {
  @include set_layout_not_desktop {
    line-height: 30px;
    font-size: 20px;
  }
}

.slice-landing-section-description {
  line-height: 27px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  @include set_layout_not_desktop {
    line-height: 24px;
    font-size: 16px;
  }
}

.slice-landing-content-wrap {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;

  max-width: $content-max-width-desktop;

  // tablet + mobile
  @include set_layout_not_desktop {
    flex-direction: column;

    padding-right: $horizontal-indents-mobile;
    padding-left: $horizontal-indents-mobile;
  }
  @media (max-width: $content-max-width-desktop - 1) {
    align-items: center;
  }
}

.slice-landing-content {
  // tablet + mobile
  @include set_layout_not_desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    width: auto;
    max-width: 331px;

    h3.slice-landing-section-title,
    p.slice-landing-section-description {
      text-align: center;
    }

    p.slice-landing-section-description {
      margin-top: 24px;
    }

    .slc-btn.slc-btn-landing {
      margin-top: 32px;
    }
  }

  p.slice-landing-section-description {
    margin-top: 32px;
  }

  .slc-btn.slc-btn-landing {
    margin-top: 40px;
  }
}
