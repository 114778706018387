@import 'variables-common';
@import 'mixins-common';

.slc-p-autocomplete-wrap {
  position: relative;
  display: block;

  slc-svg-icon-search {
    position: absolute;
    top: 13px;
    left: 13px;
    z-index: 1;
  }

  p-autocomplete {
    .p-autocomplete {
      .p-inputtext {
        padding-left: 36px;
      } 
    }
  }
}

p-autocomplete {
  .p-autocomplete,
  .p-autocomplete-multiple {
    .p-inputtext {
      @include set_input_styles(); 
    } 

    .p-autocomplete-multiple-container {
      height: auto;

      .p-autocomplete-input-token {
        padding: 0;

        input::placeholder {
          line-height: 20px;
          color: $input-placeholder-color !important;
          font-size: 14px;

          font-weight: 400;
        }
      }

      &.p-focus {
        box-shadow: none !important;
      }
    }

    .p-autocomplete-panel {
      @include set_scrollbar(#fff, $scrollbar-thumb-color-dark, 4px);
    }
  }
}
