@import 'variables-common';
@import 'variables';

// prime ng table

p-table {
  font-family: 'Noto Sans', sans-serif;
  display: block;

  .p-datatable-loading-overlay {
    display: none !important;
    background-color: rgba(#fff, 0.5);
  }

  .p-datatable-wrapper {
    border: $table-border;
    border-radius: $table-border-radius;
  }

  .p-datatable {
    table {
      width: 100%;

      // headers
      .p-datatable-thead > tr > th {


        background-color: $color-active-light;
        vertical-align: middle;
        cursor: default;

        padding: 16px 8px;
        line-height: 20px;
        color: $text-color;
        font-size: 14px;

        font-weight: bold;

        &:not(.p-frozen-column) {
          position: relative;
        }

        &:first-child {
          border-top-left-radius: $table-border-radius;
        }

        &:last-child {
          border-top-right-radius: $table-border-radius;
        }
      }

      // cells
      .p-datatable-tbody > tr {
        &:last-child td {
          border: 0;
        }

        > td {

          padding: 16px 8px;
          vertical-align: middle;
          line-height: 20px;

          color: $text-color;
          font-size: 14px;

          font-weight: 400;

          word-break: break-word;

          > img {
            vertical-align: middle;
          }
        }

        // expanded
        &.slice-p-table-row-expanded {
          background-color: #f6f6f9;

          >td {
            border-bottom: 0;
          }
        }

        th.slice-p-table-expanded-head-column,

        td.slice-p-table-expanded-body-column {
          background-color: #f6f6f9;
          cursor: default;
          padding: 8px;
          line-height: 20px;
          word-break: break-all;
          color: $text-color-light;
          font-size: 14px;
        }

        th.slice-p-table-expanded-head-column, {
          font-weight: 500;
        }
      }

      @media (min-width: 968px) {

        // left + right table paddings
        .p-datatable-thead > tr > th,
        .p-datatable-tbody > tr > td {
          &:first-child {
            padding-left: 24px;
          }

          &:last-child {
            padding-right: 24px;
          }
        }

        .p-datatable-tbody > tr:last-child > td {
          &:first-child {
            border-radius: 0 0 0 4px;
          }

          &:last-child {
            border-radius: 0 0 4px;
          }
        }
      }

      @media (max-width: 968px) {
        .p-datatable-tbody > tr {
          &:nth-child(even) {
            td {
              background-color: #f9f9f9;
            }
          }
        }

        // left + right table paddings
        .p-datatable-thead > tr > th,
        .p-datatable-tbody > tr > td {
          padding-right: 12px;
          padding-left: 12px;
        }
      }
    }

    p-paginator .p-paginator-bottom.p-paginator {
      border-width: 0;

      border-bottom-left-radius: $table-border-radius;
      border-bottom-right-radius: $table-border-radius;
      @media (max-width: $breakpoint-desktop-min) {
        justify-content: center;

        >* {
          margin-bottom: 10px;
        }

        .p-paginator-current {
          position: relative;
          margin-bottom: 0;
          padding-top: 10px;
          padding-bottom: 15px;

          width: 100%;
          height: auto;

          text-align: center;
        }
      }
    }
    // for view 'mobile layout' - header cell inside of each body cell
    @media (max-width: $breakpoint-desktop-min) {
      .p-datatable-tbody {
        > tr {
          background: transparent;

          &:first-child {
            td:first-child {
              border-top-left-radius: $table-border-radius !important;
              border-top-right-radius: $table-border-radius !important;
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }
          }

          &:last-child {
            td:last-child {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border-bottom-left-radius: $table-border-radius !important;
              border-bottom-right-radius: $table-border-radius !important;
            }
          }

          > td {
            align-items: flex-start !important;
            justify-content: flex-start !important;

            border-radius: 0;

            &:not(:last-child) {
              border-bottom: $table-border !important;
            }
          }
        }
      }
    }
  }

  p-skeleton {
    display: block;
    width: 100%;
  }

  // p-table {

  // .p-datatable {
  // // cells
  //     .p-datatable-tbody > tr {
  //       &:nth-child(even) {
  //         td {
  //           background-color: #f9f9f9;
  //         }
  //       }
}

// SCROLLED
p-table.slc-prime-ng-table-scrolled {
  th,
  td {
    &.p-frozen-column {
      box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.08);
      background-color: #fff;
      border-left: 1px solid #dee2e6 !important;
    }
  }

  .p-datatable table .p-datatable-thead > tr > th {
    .slc-table-head-title {
      white-space: normal;
    }
  }

  .p-datatable table .p-datatable-thead > tr > .p-frozen-column,
  .p-datatable table .p-datatable-tbody > tr > .p-frozen-column {
    padding-right: 8px;
    justify-content: center !important;
  }

  tbody {
    tr {
      td {
        &.p-frozen-column {
          text-align: center !important;
        }
      }
    }
  }

  .p-datatable-wrapper {
    @include set_scrollbar(#fff, $scrollbar-thumb-color-dark, 5px);
  }
}
