@import 'variables-common';
/* stylelint-disable */

$border-color: #c2c4c7;

ng-select.ng-select.slice-ng-select,
ng-select.ng-select.slice-ng-select.ng-select-single,
ng-select.ng-select.slice-ng-select.ng-select-multiple {
  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: $color-error;
    }
  }

  &.ng-select-clearable {
    .ng-select-container:not(.ng-has-value) {
      .ng-value-container {
        .ng-placeholder {
          padding-right: 0;
        }
      }
    }
  }

  &.ng-select-single:not(.ng-select-clearable) {
    .ng-select-container:not(.ng-has-value) {
      .ng-value-container {
        .ng-placeholder {
          padding-right: 0px;
        }
      }
    }
  }

  // value, placeholder, delete btn, arrow
  .ng-select-container {
    border-color: $border-color;
    border-radius: $input-border-radius;
    height: $input-height;
    box-sizing: border-box;

    &:hover {
      box-shadow: none;
    }

    // value, placeholder
    .ng-value-container {
      min-width: 0;
      padding-top: 1px;
      padding-left: $input-padding-left;

      // placeholder
      .ng-placeholder {
        top: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        padding-right: 35px;
        line-height: 20px;
        font-size: $input-text-size;
        letter-spacing: $input-text-letter-spacing;
        color: $input-placeholder-color;
      }

      // selected value

      .ng-value {
        .ng-value-label {
          padding-left: 2px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }

      // input
      .ng-input {
        input {
        }
      }
    }

    // clear btn
    .ng-clear-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      left: 5px;

      &:hover {
        opacity: 0.7;
      }

      // custom delete btn
      &::after,
      &::before {
        display: block;
        content: '';
        width: 14px;
        height: 1px;
        position: absolute;
        top: 9px;
        left: 2px;
        background-color: $text-color-light;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }

      // hide default
      .ng-clear {
        opacity: 0;
      }
    }

    // arrows
    .ng-arrow-wrapper {
      margin-left: 10px;
      margin-right: 10px;
      width: 15px;
      height: 15px;
      // background-color: #f00;

      &::before,
      &::after {
        position: absolute;
        top: 7px;
        content: '';
        display: block;
        background-color: $text-color-light;
        width: 9px;
        height: 2px;
        border-radius: 1px;
      }

      &:after {
        left: 6px;
        transform: rotate(-45deg);
        // border-radius: 0 1px 1px 0;
      }

      &:before {
        left: 1px;
        transform: rotate(45deg);
      }

      // hide default
      .ng-arrow {
        border-width: 0;
        display: none;
      }
    }
  }

  &:not(.ng-select-searchable) {
    .ng-input {
      display: none;
    }
  }

  // FOCUSED  state (not opened)

  &.ng-select-focused {
    &:not(.ng-select-opened) {
      & > .ng-select-container {
        box-shadow: none;
      }
    }
  }

  // OPENED state

  // opened state
  &.ng-select-opened {
    // value, placeholder, delete btn

    & > .ng-select-container {
      border-top-right-radius: $input-border-radius;
      border-top-left-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      border-color: $input-border-color-focus;

      .ng-value-container {
        .ng-input {
          input {
          }
        }
      }

      // arrows
      .ng-arrow-wrapper {
        transform: rotate(180deg);

        // up
        &::before {
        }

        // down
        &::after {
        }
      }
    }

    // dropdown

    .ng-dropdown-panel {
      border-radius: $input-border-radius;
      border: 1px solid #b3b9c1;
      overflow: hidden; // for border radius line

      &.ng-select-top {
        margin-bottom: 3px;

        .ng-dropdown-panel-items {
        }
      }

      &.ng-select-bottom {
        margin-top: 3px;

        .ng-dropdown-panel-items {
        }
      }

      .ng-dropdown-panel-items {
        padding: 6px;

        /* ===== Scrollbar CSS ===== */
        /* Firefox */

        scrollbar-width: auto;
        scrollbar-color: #999 #fff;

        /* Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          border: 5px solid #fff;
          border-radius: 10px;

          background-color: #999;
        }

        .ng-option {
          height: 32px;
          border-radius: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 8px;

          &:not(:first-child) {
            margin-top: 1px;
          }

          .ng-option-label {
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $text-color;
          }

          &:hover {
            background-color: #dee0ff;

            .ng-option-label {
              color: $color-primary;
            }
          }

          &.ng-option-selected,
          &.ng-option.ng-option-selected.ng-option-marked {
            background-color: $color-primary;

            .ng-option-label {
              color: #fff;
            }
          }
        }
      }
    }
  }

  &.slice-ng-select-h-40 {
    .ng-select-container {
      height: 40px;
    }
  }
}

/* stylelint-enable */
