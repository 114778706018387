@import './mixins-common';
@import './variables-common';

body {
  font-family: 'Noto Sans', sans-serif;
}

// flex styles
.slc-flex-c-c {
  @include set_flex_c_c;
}

.slc-flex-sa-c {
  @include set_flex_sa_c;
}

.slc-flex-sb-c {
  @include set_flex_sb_c;
}

.slc-flex-sb-fs {
  @include set_flex_sb_fs;
}

.slc-flex-fs-c {
  @include set_flex_start_c;
}

.slc-flex-fe-c {
  @include set_flex_end_c;
}

.slc-flex-fs-fs {
  @include set_flex_start_fs;
}

.slc-flex-fs-fe {
  @include set_flex_start_end;
}

.slc-flex-fe-fe {
  @include set_flex_end_end;
}

.slc-fluid-box {
  @include set_fluid_box();
}

.slc-fluid-box-fixed {
  @include set_fluid_box();
  position: fixed;
}

.slc-error-msg {
  @include set_error_msg();
}

.slc-success-msg {
  @include set_success_msg();
}

.slc-input-hint {
  @include set_input_hint;
}

.slc-widget {
  @include set_widget;
}

.slc-widget-shadow {
  @include set_widget_shadow;
}

.slc-text-center {
  text-align: center;
}

.slc-text-center-i {
  text-align: center !important;
}

.slc-text-left {
  text-align: left;
}

.slc-text-left-i {
  text-align: left !important;
}

.slc-text-right {
  text-align: right;
}

.slc-text-right-i {
  text-align: right !important;
}

.slc-text-center-i {
  text-align: center !important;
}

.slc-pos-rel {
  position: relative;
}

.slc-flex-dir-col {
  flex-direction: column;
}

.slc-flex-wrap-wrap {
  flex-wrap: wrap;
}

.slc-flex-grow-1 {
  flex-grow: 1;
}

// rotates
.slc-rotate-45 {
  transform: rotate(45deg) !important;
}

.slc-rotate-90 {
  transform: rotate(90deg) !important;
}

.slc-rotate-180 {
  transform: rotate(180deg) !important;
}

.slc-rotate-0 {
  transform: rotate(0) !important;
}

.slc-rotate-225 {
  transform: rotate(225deg) !important;
}

.slc-rotate-270 {
  transform: rotate(270deg) !important;
}


// line height
.slc-lh-20 {
  line-height: 20px;
}

.slc-lh-22 {
  line-height: 22px;
}

.slc-lh-24 {
  line-height: 24px;
}

.slc-lh-30 {
  line-height: 30px;
}

.slc-lh-32 {
  line-height: 32px;
}

.slc-lh-36 {
  line-height: 36px;
}

// margins
.slc-mt-2 {
  margin-top: 2px !important;
}

.slc-mt-6 {
  margin-top: 6px !important;
}

.slc-mt-8 {
  margin-top: 8px !important;
}

.slc-mt-12 {
  margin-top: 12px !important;
}

.slc-mt-16 {
  margin-top: 16px !important;
}

.slc-mt-20 {
  margin-top: 20px !important;
}

.slc-mt-24 {
  margin-top: 24px !important;
}

.slc-mt-32 {
  margin-top: 32px !important;
}

.slc-mt-40 {
  margin-top: 40px !important;
}

.slc-mt-48 {
  margin-top: 48px !important;
}

.slc-mt-56 {
  margin-top: 56px !important;
}

.slc-mt-0 {
  margin-top: 0 !important;
}

.slc-ml-0 {
  margin-left: 0 !important;
}

.slc-ml-8 {
  margin-left: 8px !important;
}

.slc-ml-6 {
  margin-left: 6px !important;
}

.slc-ml-10 {
  margin-left: 10px !important;
}

.slc-ml-12 {
  margin-left: 12px !important;
}

.slc-ml-16 {
  margin-left: 16px !important;
}

.slc-ml-24 {
  margin-left: 24px !important;
}

.slc-ml-32 {
  margin-left: 32px !important;
}

// margin bottom

.slc-mb-8 {
  margin-bottom: 8px !important;
}

.slc-mb-16 {
  margin-bottom: 16px !important;
}

.slc-mb-24 {
  margin-bottom: 24px !important;
}

// margin right

.slc-mr-0 {
  margin-right: 0 !important;
}

.slc-mr-4 {
  margin-right: 4px !important;
}

.slc-mr-8 {
  margin-right: 8px !important;
}

.slc-mr-10 {
  margin-right: 10px !important;
}

.slc-mr-12 {
  margin-right: 12px !important;
}

.slc-mr-16 {
  margin-right: 16px !important;
}

.slc-mr-24 {
  margin-right: 24px !important;
}

.slc-mr-30 {
  margin-right: 30px !important;
}


// paddings
.slc-p-0 {
  padding: 0 !important;
}

.slc-p-16 {
  padding: 16px !important;
}

.slc-p-24 {
  padding: 24px !important;
}

.slc-p-32 {
  padding: 32px !important;
}

.slc-pt-32 {
  padding-top: 32px !important;
}

.slc-pt-16 {
  padding-top: 16px !important;
}

.slc-pb-0 {
  padding-bottom: 0 !important;
}

.slc-pb-8 {
  padding-bottom: 8px !important;
}

.slc-pb-16 {
  padding-bottom: 16px !important;
}

.slc-pr-0 {
  padding-right: 0 !important;
}
// gaps;
.slc-gap-16 {
  gap: $gap-default !important;
}

// common widths
.slc-w-pct-100 {
  width: 100% !important;
}

.slc-text-ellipsis {
  display: block;

  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  line-height: 1.25;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.slc-text-ellipsis {
  display: block;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.slc-list-header {
  line-height: 20px;
  color: $text-color-light;
  font-size: 14px;

  font-weight: 400;
}

.slc-widget-title {
  line-height: 27px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}

.slc-widget-title-small {
  line-height: 20px;
  color: $text-color-light;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

.slc-fw-400 {
  font-weight: 400;
}

.slc-fw-500 {
  font-weight: 500;
}

.slc-fw-600 {
  font-weight: 600;
}

.slc-fw-700 {
  font-weight: 700;
}

.slc-fw-800 {
  font-weight: 800;
}

.slc-fw-900 {
  font-weight: 900;
}

.slc-scrollbar-white {
  @include set_scrollbar($scrollbar-thumb-color-dark, #fff, 3px);
}

.slc-scrollbar-dark {
  @include set_scrollbar($scrollbar-thumb-color-light, #132743, 3px);
}

.slc-max-w-100-pc {
  max-width: 100%;
}

.slc-max-w-100px {
  max-width: 100px;
}

.slc-max-w-120px {
  max-width: 120px;
}

.slc-max-w-150px {
  max-width: 150px;
}

.slc-max-w-200px {
  max-width: 200px;
}

.slc-height-auto {
  height: auto;
}

.slc-height-100-pc {
  height: 100% !important;
}

.slc-width-100-pc {
  width: 100%;
}

.slc-text-color-main {
  color: $text-color;
}

.slc-text-color-light {
  color: $text-color-light;
}

.slc-text-color-primary {
  color: $color-primary;
}

.slc-text-color-error {
  color: $color-error;
}

.slc-text-color-success {
  color: $color-success;
}

.slc-text-align-left {
  text-align: left;
}

.slc-text-align-right {
  text-align: right;
}

.slc-word-breal-all {
  word-break: break-all;
}

.slc-fs-14 {
  font-size: 14px;
}

.slc-fs-16 {
  font-size: 16px;
}

.slc-fs-20 {
  font-size: 20px;
}

.slc-pointer-events-none {
  pointer-events: none !important;
  cursor: default !important;
}

.slc-word-break-all {
  word-break: break-all;
}

.slc-search-field-wrap {
  position: relative;
  display: block;

  slc-svg-icon-search {
    position: absolute;
    top: 13px;
    left: 13px;
    z-index: 1;
  }

  .slc-input {

    padding-left: 36px;
  }

  i {
    &.pi-spin {
      position: absolute;
      top: 13px;
      right: 13px;
      z-index: 1;
    }
  }
}
