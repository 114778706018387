@import './variables-common';

// ----------------------------------- INPUT -------------------

@mixin set_input_styles {
  border: $input-border;
  border-radius: $input-border-radius;
  border-color: $input-border-color;

  background-color: $input-background-color;
  padding-right: $input-padding-right;
  padding-left: $input-padding-left;
  width: 100%;
  height: $input-height;
  letter-spacing: $input-text-letter-spacing;
  color: $input-text-color;

  font-size: $input-text-size;

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:hover {
    outline: none;
    border-color: $input-border-color;

    box-shadow: none;
  }

  &:focus {
    outline: none;
    border-color: $input-border-color-focus;
    box-shadow: none;

    background-color: $input-background-color-focus;
  }

  &:disabled {
    border-color: $input-disabled-border-color !important;

    background-color: $input-disabled-bg !important;

    cursor: not-allowed;
  }

  &-with-bg {
    box-sizing: border-box;
    border: 1px solid #dfe1e2;
    border-radius: 8px;

    background: #fcfcff !important;
  }

  &.ng-touched.ng-invalid {
    border-color: $color-error;
  }
}

// ----------------------------------- FLEX --------------------------

@mixin set_flex_c_c() {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_sa_c() {
  display: flex;
  align-items: center;
  justify-content: space-around;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_sb_c() {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_sb_fs() {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_start_c() {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_end_c() {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_start_fs() {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_start_end() {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  min-width: 0; // for text ellipsis
}
@mixin set_flex_end_end() {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  min-width: 0; // for text ellipsis
}
// --------------------------------- POSITION (absolute, fixed) ---------------

@mixin set_pos_fixed($top, $right, $bottom, $left) {
  position: fixed;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin set_pos_absolute($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// --------------------------------- fluid ---------------

@mixin set_fluid_box() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// ------------------------------- BREAKPOINTS

// mobile
@mixin set_layout_mobile {
  @media (min-width: $breakpoint-mobile-min) and (max-width: $breakpoint-mobile-max) {
    @content;
  }
}

// tablet
@mixin set_layout_tablet {
  @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
    @content;
  }
}

// desktop
@mixin set_layout_desktop {
  @media (min-width: $breakpoint-desktop-min) and (max-width: $breakpoint-desktop-max) {
    @content;
  }
}

// not mobile (tablet + desktop)
@mixin set_layout_not_mobile {
  @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-desktop-max) {
    @content;
  }
}

// not desktop (mobile + tablet)
@mixin set_layout_not_desktop {
  @media (min-width: $breakpoint-mobile-min) and (max-width: $breakpoint-tablet-max) {
    @content;
  }
}

// ERRORS

@mixin set_error_msg {
  margin-top: 4px;
  width: 100%;
  line-height: 18px;

  color: $color-error;
  font-size: 13px;
  font-weight: normal;

  font-style: normal;
}

// success
@mixin set_success_msg {
  margin-top: 4px;
  width: 100%;
  line-height: 18px;

  color: $color-success;
  font-size: 13px;
  font-weight: normal;

  font-style: normal;
}


@mixin set_input_title {
  padding-bottom: 8px;
  line-height: 20px;
  font-size: 14px;

  font-weight: 600;
  font-style: normal;

  span {
    color: $color-error;
  }
}

@mixin set_input_hint {
  margin-top: 4px;
  line-height: 18px;

  color: $text-color-light;
  font-family: Noto Sans;
  font-size: 13px;

  font-weight: normal;
  font-style: normal;
}

// ----------------------- WIDGET BOX -----------

@mixin set_widget {
  border: $border-widget;
  border-radius: 10px;
  height: 100%;
}

@mixin set_widget_shadow {
  @include set_widget;
  box-shadow: $box-shadow-widget;
}

// ---------------------- scroll bar -----------
@mixin set_scrollbar($trackBg, $thumbBg, $thumbBorder) {
  /* ===== Scrollbar CSS ===== */
  /* Firefox */

  scrollbar-width: auto;
  scrollbar-color: #999 $trackBg;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $trackBg;
  }

  &::-webkit-scrollbar-thumb {
    border: $thumbBorder solid $trackBg;
    border-radius: 10px;

    background-color: $thumbBg;
  }
}

// --------------------- RADIO buttons--------

@mixin set_radio_btn_size_24 {
  button {
    .slice-radio-icon {
      margin-right: 16px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
    }

    .slice-radio-label {
      line-height: 22px;
      font-size: 16px;
      font-weight: 600;
    }

    ::ng-deep slc-svg-icon-checkmark {
      svg {
        width: 12px !important;
        height: 10px !important;
      }
    }
  }
}

// ------------------------ checkbox --------

@mixin set_checkbox_size_24 {
  .checkmark {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;

    ::ng-deep slc-svg-icon-checkmark {
      top: 5px;
      left: 5px;

      svg {
        width: 14px !important;
        height: 12px !important;
      }
    }
  }

  .title {
    margin-left: 7px;
    line-height: 22px;
    font-size: 16px;

    font-weight: 600;
  }
}

// ----------------------- OTHER -----------
