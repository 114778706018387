.slice-calendar-event {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: default;

  @media (max-width: 599px) {
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
  }
}
