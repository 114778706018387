@import 'variables-common';

$border-color: #c0c4d6;

p-selectbutton {
  .p-selectbutton.p-buttonset {

    box-sizing: border-box;
    display: flex;
    border-radius: $btn-border-radius;

    .p-button {
      border: 0;
      border: 1px solid $border-color;
      padding: 10px 16px;

      &:first-child {
        border-top-left-radius: $btn-border-radius;
        border-bottom-left-radius: $btn-border-radius;
      }

      &:last-child {
        border-top-right-radius: $btn-border-radius;
        border-bottom-right-radius: $btn-border-radius;
      }

      &:not(:first-child) {
        border-left: 0;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 18px;

        color: #828a96;
        font-size: 14px;

        font-weight: 600;
      }

      &.p-highlight {
        box-shadow: inset 0 0 4px rgba(130, 138, 150, 0.08);
        background-color: #d8defd;

        &:hover {
          border-color: $border-color;
        }

        span {
          color: #384582;
        }
      }
    }
  }
}
