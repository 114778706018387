@import 'variables-common';

button {
  margin: 0;
  border: 0;
  box-shadow: none;

  background-color: transparent;
  padding: 0;

  &:disabled {
    cursor: default;
  }
}

.slc-btn {
  -webkit-appearance: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $btn-border-radius;

  cursor: pointer;
  padding: 0 $btn-paddings-horizontal;

  min-width: 0;
  height: $btn-height;

  &:disabled {
    border-color: $btn-disabled-bg !important;

    background-color: $btn-disabled-bg !important;

    cursor: not-allowed;

    &:hover {
      background-color: #e8edf3;

      cursor: not-allowed;

      span {
        color: $text-color-light;

        font-size: 14px;
      }
    }

    span {
      color: $text-color-light;

      font-size: 14px;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    white-space: nowrap;
    font-size: 14px;

    font-weight: 500;
  }

  &-with-icon {
    .slc-svg-icon {
      margin-left: 8px;
    }
  }

  &-primary {
    background-color: $color-primary;

    &:hover:not(:disabled) {
      background-color: #2c63f0;
    }

    span {
      color: #f7f9fa;
    }
  }

  &-primary-transparent {
    background-color: #ebedfd;

    &:hover:not(:disabled) {
      background-color: darken($color: #ebedfd, $amount: 3);
    }

    span {
      color: $color-primary;;
    }
  }

  &-success {
    background-color: $color-success;

    &:hover:not(:disabled) {
      background-color: $color-success;
    }

    span {
      color: #f7f9fa;
    }
  }

  &-purple-light {
    background-color: #e9e9fe;

    &:hover:not(:disabled) {
      background-color: rgba(#e9e9fe, 0.8);
    }

    span {
      color: $color-primary;
    }
  }

  &-dark {
    background-color: #606974;


    &:disabled {
      background-color: rgba(#606974, 0.45) !important;

      span {
        color: #f7f9fa !important;
      }
    }

    &:hover:not(:disabled) {
      background-color: rgba(#606974, 0.85) !important;

      span {
        color: #f7f9fa;
      }
    }

    span {
      color: #f7f9fa;
    }
  }

  &-darker {
    background-color: #0d193f;

    &:hover:not(:disabled) {
      background-color: #0d193f;
    }

    span {
      color: #fff;
    }
  }

  &-error {
    background-color: $color-error;

    &:hover:not(:disabled) {
      background-color: $color-error;
    }

    span {
      color: #fff;
    }
  }

  &-error-light {
    background-color: $color-error-light;

    &:hover:not(:disabled) {
      background-color: $color-error-light;
    }

    span {
      color: #fff;
    }
  }

  &-error-dark {
    background-color: $color-error-dark;

    &:hover:not(:disabled) {
      background-color: $color-error-dark;
    }

    span {
      color: #fff;
    }
  }

  &-outline-white {
    border: 1px solid #fff;

    background-color: transparent;

    &:hover:not(:disabled) {
      background-color: transparent;
    }

    span {
      color: #fff;
    }
  }

  &-outline-grey {
    border: 1px solid $text-color-light;

    background-color: #fff;

    &:hover:not(:disabled) {
      background-color: #fff;
    }

    span {
      color: $text-color-light;
    }
  }

  &-outline-blue {
    border: 1px solid $color-primary;

    background-color: #fff;

    &:hover:not(:disabled) {
      background-color: #fff;
    }

    span {
      color: #434ddb;
    }
  }

  &-outline-warning {
    border: 1px solid $color-warning;

    background-color: #fff;

    &:hover:not(:disabled) {
      background-color: #fff;
    }

    span {
      color: $color-warning;
    }
  }

  &-landing {
    min-width: 172px;

    span {
      font-weight: bold;
    }
  }

  &-h-24 {
    height: 24px;
  }

  &-h-28 {
    height: 28px;
  }

  &-h-36 {
    height: 36px;
  }

  &-h-44 {
    height: 44px;
  }

  &-h-48 {
    height: 48px;
  }

  &-h-56 {
    height: 56px;
  }

  &-h-60 {
    height: 60px;
  }

  &-h-94 {
    height: 94px;
  }

  &-h-116 {
    height: 116px;
  }

  &-confirmation {
    span {
      font-weight: bold;
    }
  }

  &-loading {
    border: 1px solid #c2c4c7;
    border-radius: $btn-border-radius;

    background-color: rgba(#fff, 0.5);

    cursor: default;
    pointer-events: none;

    &::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 5px;
      margin-top: -7px;

      width: 15px;
      height: 15px;

      animation: spin 1.5s infinite linear;
      content: url('/assets/nx-common/loader.svg');
    }

    span {
      color: #c2c4c7;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(359deg);
      }
    }
  }
}
