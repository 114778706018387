@import 'variables-common';

// hide arrows for numbers types
input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: none;
}

.slc-input {
  @include set_input_styles();
}

textarea.slc-input {

  @include set_scrollbar(#fff, $scrollbar-thumb-color-dark, 4px);
  padding: 8px 16px;
  height: auto;

  line-height: 20px;

}

.slc-input-title {
  @include set_input_title();
}
