// header
$header-agency-desktop: 144px;
$header-height-desktop: 84px;
$header-height-mobile: 76px;

// app menu
$menu-width-opened: 324px;
$menu-width-closed: 60px;

// main content
$content-max-width-desktop: 90vw;

$horizontal-indents-desktop: 45px;

// indents
$horizontal-indents-tablet: 40px;
$horizontal-indents-mobile: 16px;

// footer
$footer-height-desktop: 102px;

// landing
$brand-bg-gradient: linear-gradient(180deg, #424ddb 20.62%, #4730a3 87.18%);
$landing-bg-light: #f5f5f5;

// wizard
$wizard-bg-color-mobile: #f6f6f9;
$wizard-content-padding-indent-mobile: 14px;

// z-indexes
$z-i-app-toast: 4000;
$z-i-app-datepicker-dropdown: 3600;
$z-i-app-tooltip: 3500;
$z-i-app-modal: 3000;
$z-i-app-menu: 2500;
$z-i-app-header: 2000;
$z-i-side-bar: 10;


// channels colors
$instagram-bg-color: #d7dcde;
$tiktok-bg-color: #ffcde2;
$youtube-bg-color: #ffb4b3;
$anchor-bg-color: #daceed;
$podcast-bg-color: #daceed;
