@import 'variables-common';
@import 'variables';

$padding-horizontal: 32px;

p-dialog {
  .p-dialog {
    font-family: 'Noto Sans', sans-serif;
  }

  > .p-dialog-mask {
    z-index: 3000 !important;

    background-color: rgba(#000, 0.36);
  }

  .p-dialog {
    .p-dialog-header {
      border-bottom: 1px solid #d5d7db;
      border-radius: $modal-border-radius $modal-border-radius 0 0;
      padding: 22px $padding-horizontal;

      .p-dialog-header-close {
        position: relative;
        left: 12px;

        &:hover {
          background-color: transparent !important;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .p-dialog-content {
      @include set_scrollbar(transparent, $scrollbar-thumb-color-dark, 5px);
      padding: 22px $padding-horizontal;

      color: $text-color-light;

      &::-webkit-scrollbar {
        width: 6px;
      }
    }

    .p-dialog-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #d5d7db;
      border-radius: 0 0 $modal-border-radius $modal-border-radius;
      padding-right: 32px;
      padding-bottom: 0;

      padding-left: 32px;
      min-height: 80px;

      p-footer {
        flex-grow: 1;
      }

      .slc-btn {
        margin: 0;
        margin-left: 16px;
      }
    }
  }

  .p-dialog-right {
    > .p-dialog {
      margin: 0;
      width: 487px;
      height: 100vh;
      max-height: 100%;

      > .p-dialog-header {
        border-radius: 0;
      }

      > .p-dialog-content {
        border-radius: 0;
      }

      > .p-dialog-footer {
        justify-content: center;
        border-top: 1px solid #d5d7db;
        border-radius: 0;

        padding: 40px $padding-horizontal;

        .slc-btn {
          margin: 0;
        }
      }
    }
  }
}

p-dialog {
  @media (max-width: 768px) {
    .p-dialog {
      margin: 50px auto;
      max-width: 90%;
      height: auto;
      max-height: 90%;

      .p-dialog-header {
        border-radius: 8px 8px 0 0;
      }
      // !!! - all modals must be with headers
      // .p-dialog-content {
      // border-radius: 8px 8px 0 0;
      // }
      .p-dialog-footer {
        border-radius: 0 0 8px 8px;
      }

      .p-dialog-header,
      .p-dialog-content,
      .p-dialog-footer {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}
