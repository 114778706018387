/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */

@import 'node_modules/bootstrap/scss/bootstrap';
@import '~@ng-select/ng-select/themes/default.theme.css';

// video player
@import '~video.js/dist/video-js.css';

// common
@import 'reset-common';
@import 'variables-common';
@import 'variables';
@import './styles/bootstrap'; // reinit bootstrap styles
@import 'fonts-common';
@import 'global-common';
@import 'mixins-common';
@import 'elements-common';
@import 'animate.css';

// local
@import './styles/elements/modals';
@import './styles/elements/ng-select';
@import './styles/elements/datepicker';
@import './styles/elements/toast';
@import './styles/elements/autocomplete';
@import './styles/elements/full-calendar';

// TODO - check this - is it really need landing?
@import './styles/landing';

html,
body {
  @include set_scrollbar(transparent, $scrollbar-thumb-color-dark, 3px);

  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 6px;
  }
}

body {
  background-color: $body-bg-color;
  color: $text-color;

  &.slice-body-not-scrollable {
    overflow: hidden;
  }
}

.slice-initial-logo {
  @keyframes fadein {
    from { opacity: 0.5; }
    to { opacity: 1; }
  }
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  background-color: #fff;

  -webkit-animation: fadein 0.8s ease-in alternate infinite;
  -moz-animation: fadein 0.8s ease-in alternate infinite;
  animation: fadein 0.8s ease-in alternate infinite;
}

p {
  margin-bottom: 0;
}

.slice-main-content {
  margin: 0 auto;
  padding: 32px $horizontal-indents-desktop 40px $horizontal-indents-desktop;
  max-width: $content-max-width-desktop;
  min-height: calc(100vh - #{$header-height-desktop});
  @include set_layout_tablet {
    padding: 20px $horizontal-indents-tablet 40px $horizontal-indents-tablet;
  }

  @include set_layout_mobile {
    padding: 20px $horizontal-indents-mobile 40px $horizontal-indents-mobile;
    min-height: calc(100vh - #{$header-height-mobile});
  }
}

hr {
  margin-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid #d5d7db;
}

.slice-chart-title {
  line-height: 20px;
  color: $text-color;
  font-size: 14px;

  font-weight: 700;
}

.p-tooltip {
  z-index: $z-i-app-tooltip !important;
}

