@import 'mixins-common';

p-calendar {
  .p-calendar {
    input.p-inputtext {
      @include set_input_styles();
      padding-right: 40px;
    }

    .p-datepicker-buttonbar {
      padding-top: 7px;
      padding-bottom: 0;
    }

    .p-datepicker-calendar {
      tr td span.p-disabled {
        opacity: 0.4;
      }
    }
  }
}


.slc-datepicker-wrap {
  position: relative;

  p-calendar {
    display: block;
    width: 100%;
    
    .p-calendar {
      width: 100%;
    }
  }

  slc-svg-icon-calendar {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
