@import 'variables-common';

.slc-h-1,
.slc-h-2,
.slc-h-3,
.slc-h-4,
.slc-h-5,
.slc-h-6 {
  color: #0e0e0e;

  font-weight: bold;
  font-style: normal;
}

.slc-h-1 {
  line-height: 78px;
  font-size: 52px;
}

.slc-h-2 {
  line-height: 56px;
  font-size: 42px;
}

.slc-h-3 {
  line-height: 54px;
  font-size: 36px;
}

.slc-h-4 {
  line-height: 46px;
  font-size: 32px;
}

.slc-h-5 {
  line-height: 36px;
  font-size: 24px;
}

.slc-h-6 {
  line-height: 30px;
  font-size: 20px;  
}
