@import 'variables-common';

.p-tooltip {
  background-color: transparent !important;

  .p-tooltip-arrow {
    opacity: 0;
  }

  .p-tooltip-text {
    border: 1px solid #c2c4c7;
    border-radius: 6px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);

    background-color: #fff !important;
    line-height: 20px;
    color: $text-color !important;
    font-size: 14px;

    font-weight: 400;
    white-space: initial !important;

    strong {
      font-weight: 600;
    }
  }
}

