@import 'variables-common';
@import 'mixins-common';
@import 'variables';

p-paginator .p-paginator {
  justify-content: flex-end;
  border: 0;

  padding: 0;
  padding: 16px 0;

  .p-paginator-current {
    position: absolute;
    left: 0;

    margin: 0;
    padding: 0;
    line-height: 20px;

    color: $text-color-light;
    font-size: 13px;

    font-weight: 500;
  }

  .p-paginator-element,
  .p-paginator-pages .p-paginator-page,
  .p-paginator-pages .p-paginator-element {
    box-sizing: border-box;
    margin: 0;
    margin-right: 8px;
    border: 1px solid #e8edf3;
    border-radius: 6px;
    border-radius: 6px;

    background: #fbfbfb;
    width: 28px;
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    min-height: 28px;
    max-height: 28px;
    line-height: 20px;
    font-size: 13px;

    font-weight: 500;

    &.p-highlight {
      background: #434ddb;
      color: #fff;
    }
  }

  p-dropdown {
    >.p-dropdown {
      margin: 0;
      margin-left: 20px;
      border: $table-border;
      height: 28px;

      &:hover {
        border: $table-border;
      }

      .p-dropdown-label {

        padding: 0;
        padding-left: 7px;
        vertical-align: middle;

        line-height: 25px;
      }

      .p-dropdown-trigger {
        padding: 0 8px;
        width: auto;

        .p-dropdown-trigger-icon {
          &::before {
            position: relative;
            top: -2px;

            font-size: 10px;
          }
        }
      }
    }

    .p-dropdown-panel {
      .p-dropdown-items-wrapper {
        @include set_scrollbar(#fff, $scrollbar-thumb-color-dark, 4px);
      }
    }
  }
}
