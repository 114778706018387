@import 'variables-common';
@import 'variables';

p-toast {
  .p-toast {

    opacity: 1;
    width: auto;
    max-width: 280px;

    &.p-toast-top-right {
      z-index: $z-i-app-toast !important;
    }

    p-toastitem {
      .p-toast-message {
        .p-toast-message-content {
          .p-toast-message-text {
            margin-right: 5px;
            margin-left: 0;
            min-width: 0;
          }
        }

        &.p-toast-message-error {
          border: solid $color-error-light;
          border-width: 0 0 0 6px;
          border-width: 0 0 0 6px;

          background-color: #fff;
          color: $color-error-light;
        }

        &.p-toast-message-success {
          border: solid $color-success;
          border-width: 0 0 0 6px;
          border-width: 0 0 0 6px;

          background-color: #fff;
          color: $color-success;
        }
      }

      .p-toast-detail {
        // word-break: break-all;
      }

      .p-toast-icon-close {
        min-width: 25px;
        min-height: 25px;
      }

      .p-toast-message-icon {
        display: none;
      }
    }
  }
}
