@import 'variables-common';
/* stylelint-disable */

ng-select.ng-select,
ng-select.ng-select.ng-select-single {
  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: $color-error;
    }
  }

  // value, placeholder, delete btn, arrow
  .ng-select-container {
    border-color: #c2c4c7;
    border-radius: 6px;
    // hover

    &:hover {
    }

    // value, placeholder
    .ng-value-container {
      // placeholder
      .ng-placeholder {
      }

      // selected value

      .ng-value {
        .ng-value-label {
        }
      }

      // input
      .ng-input {
        top: 8px;

        input {
        }
      }
    }

    // clear btn
    .ng-clear-wrapper {
      // custom delete btn
      &::after {
      }

      // hide default
      .ng-clear {
      }
    }

    // arrows
    .ng-arrow-wrapper {
      &::before,
      &::after {
        // content: '\e904';
      }

      // up
      &::before {
        // display: none;
        // content: '\e904';
      }

      // down
      &::after {
        // content: '\e905';
        // display: inline-block;
      }

      // hide default
      .ng-arrow {
      }
    }
  }
}

// FOCUSED  state (not opened)

ng-select.ng-select,
ng-select.ng-select.ng-select-single {
  &.ng-select-focused {
    &:not(.ng-select-opened) {
      & > .ng-select-container {
        box-shadow: none;
        // outline: no;
      }
    }
  }
}

// OPENED state

ng-select.ng-select.ng-select-single {
  // opened state
  &.ng-select-opened {
    // value, placeholder, delete btn

    & > .ng-select-container {
      .ng-value-container {
        .ng-input {
          input {
          }
        }
      }

      // arrows
      .ng-arrow-wrapper {
        // up
        &::before {
        }

        // down
        &::after {
        }
      }
    }

    // dropdown

    .ng-dropdown-panel {
      box-shadow: 0px 16px 32px rgba(14, 14, 14, 0.12);
      border-radius: 6px;

      &.ng-select-top {
        .ng-dropdown-panel-items {
        }
      }

      &.ng-select-bottom {
        .ng-dropdown-panel-items {
        }
      }

      .ng-dropdown-panel-items {
        .ng-option {
          &:hover {
          }

          &.ng-option-selected,
          &.ng-option.ng-option-selected.ng-option-marked {
          }
        }
      }
    }
  }
}

.ng-select.ng-select,
ng-select.ng-select.ng-select-single {
  &.slc-ng-select-disabled {

    .ng-select-container {
      pointer-events: none;
      border-color: #c2c4c7;
      background-color: #f9f9f9;
    }
  }
}
