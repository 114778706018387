a {

  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.slc-link {

  text-decoration: underline;
  line-height: 20px;
  color: #1576ff;
  font-size: 14px;
  font-weight: 500;
}
