p-carousel {
  .p-carousel .p-carousel-content {
    .p-carousel-next,
    .p-carousel-prev {
      box-sizing: border-box;
      border: 1px solid #e8edf3;
      border-radius: 100px;

      background: #fbfbfb;
      width: 28px;
      height: 28px;

      .p-carousel-prev-icon,
      .p-carousel-next-icon {
        &::before {
          position: relative;
          top: -2px;

          color: $color-primary;
          font-size: 11px;

          font-weight: bold;
        }
      }
    }
  }
}
