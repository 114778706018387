@import 'variables';

p-calendar {
  .p-calendar {
    .p-datepicker {
      z-index: $z-i-app-datepicker-dropdown !important;
    }
  }
}

.p-datepicker {
  z-index: $z-i-app-datepicker-dropdown !important;
}
