// -------------------- COLORS -------------------
$color-primary-light: #1576ff;
$color-primary: #434ddb;
$color-primary-dark: #424cda;
$color-success: #4dbb7b;

$color-error-dark: #d34141;
$color-error: #fb4f4f;
$color-error-light: #ff7182;

$color-white: #fff;
$color-active: #d6d2ff;
$color-active-light: #e8e8fe;

$color-warning: #e78c49;

// --------------------SPECIAL SOCIAL COLORS -------------------
$bg-color-instagram: #d7dcde;
$bg-color-youtube: #ffb4b2;
$bg-color-tiktok: #ffcde2;
$bg-color-anchor: #daceed;

//---------------------- BODY ----------------
$body-bg-color: #f6f6f9;
$menu-bg-color: #eeeff1;
$menu-item: #0d193f;

// --------------------- TEXT ----------------
$text-color: #0e0e0e;
$text-color-light: #606974;
$text-color-light-grey: #a5a5a5;

// --------------------- BORDERS ----------------
$border-color: #e8edf3;
$border-color-light: #d5d7db;

$border-widget: 1px solid #eaeaeb;

// --------------------- INPUTS ---------------
$input-height: 40px;
$input-border: 1px solid;
$input-border-color: #c2c4c7;
$input-border-color-focus: $color-primary-light; // #98a5b2;
$input-border-radius: 6px;
$input-placeholder-color: rgba($text-color, 0.2);
$input-padding-left: 12px;
$input-padding-right: 12px;
$input-background-color: #fff;
$input-background-color-focus: #fff;
$input-text-color: $text-color;
$input-text-size: 14px;
$input-text-letter-spacing: 0;
$input-delete-btn-font-size: 11px;
$input-delete-btn-color: rgba($text-color, 0.5);

$input-disabled-bg: #fcfcff;
$input-disabled-border-color: #dfe1e2;

// ------------------------ BUTTONS ---------------
$btn-height: 40px;
$btn-border-radius: 6px;
$btn-paddings-horizontal: 24px;
$btn-disabled-bg: #f3f6fc;

// ----------------------- MODAL WINDOWS -------------
$modal-border-radius: 10px;

// ------------------------ TABLES ---------------
$table-border-color: #d5d7db;
$table-border: 1px solid $table-border-color;
$table-border-radius: 6px;

// ------------------------ Z-INDEXES ------------------
// for each project own

// ----------------------- BREAKPOINTS ------------------

// mobile
$breakpoint-mobile-min: 0;
$breakpoint-mobile-max: 599px;

// tablet
$breakpoint-tablet-min: 600px;
$breakpoint-tablet-max: 959px;

// desktop
$breakpoint-desktop-min: 960px;
$breakpoint-desktop-max: 5000px;

// ----------------------- BOX SHADOWS ------------------

$box-shadow-widget: 0 2px 4px rgba(0, 0, 0, 0.08);

// ----------------------- GAPS ------------------

$gap-default: 16px;

// ---------------------- SCROLL BAR -------------
$scrollbar-thumb-color-dark: #999; // #132743;
$scrollbar-thumb-color-light: #fff;

// ---------------------- BACKGROUND LINEAR GRADIENT -------------
$linear-gradient-primary-dark: 46.15deg, #23245b 29.29%, #434ddb 211.11%;
