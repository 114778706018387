@import 'variables-common';
@import 'mixins-common';

p-autocomplete {
  .p-autocomplete {
    .p-inputtext {
      @include set_input_styles(); 
    } 

    .p-autocomplete-multiple-container {
      height: auto;

      .p-autocomplete-input-token {
        padding: 0;

        input {
          &::placeholder {
            line-height: 20px;
            color: $text-color-light;
            font-size: 14px;

            font-weight: 400;
          }
        }
      }

      &.p-focus {
        box-shadow: none !important;
      }
    }

    .p-autocomplete-panel {
      @include set_scrollbar(#fff, $scrollbar-thumb-color-dark, 4px);
    }
  }
}
